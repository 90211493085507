body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  /* font-family: 'Red Hat Display'; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


/* @font-face{
  font-family: 'Red Hat Display';
  src: local('Red Hat Display'), url(./fonts/RedHatDisplay-Regular.ttf) format('tff');
  src: local('Red Hat Display'), url(./fonts/RedHatDisplay-Bold.ttf) format('tff');
  src: local('Red Hat Display'), url(./fonts/RedHatDisplay-Medium.ttf) format('tff');
} */

