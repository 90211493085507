.slide-container {
    width: 100%;
    margin: auto;
    position: relative;
}
  
  .each-slide > div {
    display: flex;
    align-items: center;
    justify-content: center;
    background-size: cover;
    min-height: 132px;
  }

  .each-slide > img {
    width: 100%;
    height: auto;
  }
    
  .each-slide span {
    padding: 20px;
    font-size: 20px;
    background: #efefef;
    text-align: center;
  }
  
  .each-fade {
    display: flex;
  }
   
  .each-fade .image-container {
    width: 75%;
    overflow: hidden;
  }
   
  .each-fade .image-container img {
    width: 100%;
  }
   
  .each-fade h2 {
    width: 25%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
    background: #adceed;
  }


  @media only screen and (min-width : 768px){
    /* .each-slide > div {
      min-height: 400px;
    } */

  }