
.carousel {
  width: 100%;
}

.slide-box {
  display: flex;
  justify-content: space-around;
}

.carousel-inner{
    width: 90%;
    margin: auto;
}
.carousel-control-prev{
    width: 3%;
}
.carousel-control-prev-icon {
    position: absolute;
    left: 0px;
    /* top:45%; */
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23000' viewBox='0 0 8 8'%3E%3Cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3E%3C/svg%3E") !important;
}

.carousel-control-next{
    width: 3%;
}

.carousel-control-next-icon {
    position: absolute;
    right: 0px;
    /* bottom: 45%; */
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23000' viewBox='0 0 8 8'%3E%3Cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3E%3C/svg%3E") !important;
}


.slide-box .carousal-cat-item {
    -ms-flex: 0 0 45%;
    flex: 0 0 45%;
    width: 45%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background: #FDFDFD;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
  }


.carousal-cat-item img{
    border-radius: 5px;
    width: 100%;
}

.carousal-cat-item h3{
    height: 48px;
    /* line-height: 58px; */
    padding: 0px 10px;
    /* margin: auto; */
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
  /* height: 200px; */
}
.carousal-cat-item:hover{
    text-decoration: none;
}

/* @media (min-width: 576px) and (max-width: 767.98px) {
  .slide-box img {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 40%;
  }
} */

@media (min-width: 768px) and (max-width: 991.98px) {
  .slide-box .carousal-cat-item {
    -ms-flex: 0 0 30%;
    flex: 0 0 30%;
    width: 30%;
  }
}

@media (min-width: 992px)
{
  .carousel-inner{
        width: 97%;
    }
    
  .slide-box .carousal-cat-item {
    -ms-flex: 0 0 22%;
    flex: 0 0 22%;
    width: 22%;
  }
}

.carousel-caption {
  background-color: rgba(0, 0, 0, 0.5);
  padding: 20px;
  border-radius: .5rem;
}