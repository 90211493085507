.thankyou{
    height: 100vh;
    background-color: #E5E5E5;
}

.tile{
    margin-top: 20vh;
    margin-left: 5%;
    margin-right: 5%;
    margin-bottom: 10vh;
}
.imgDiv{
    width: 70vw;
}
@media only screen and (min-width:768px) {
    .tile{
        margin-top: 10vh;
        margin-left: 20%;
        margin-right: 20%;
        margin-bottom: 10vh;
    }

    .imgDiv{
        width: 30vw;
    }
}
