.spLogo{
    position: absolute;
    /* width: 77.49px;
    height: 48.55px; */
    /* left: 11.47px; */
    top: 17px;
}
.topBanner{
    background-image: url("../../Images/salesPage_1.svg");
    background-repeat: no-repeat;
    padding-top: 50vh;
    padding-left: 10vw;
    padding-right: 10vw;
    padding-bottom: 10vh;
}

.story{
    position: relative;
    top: 50vh;
    margin: 0% 15%;
}

.topBanner > h1 {
    font-weight: 500;
    color: #54B94D;
    font-size: 32px;
}

.topBanner > h5 {
    color: white;
    font-size: 16px;
}

.text {
    margin-left: 10%;
    margin-right: 10%;
    width: 80%;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 133.3%;
    color: #000000;    
}

.spHeading{
    margin-left: 10%;
    margin-right: 10%;
    width: 80%;
    font-style: normal;
    font-weight: 500;
    font-size: 28px;
    line-height: 37px;
    color: #54B94D;
    margin-top: 20px;
    margin-bottom: 40px;
}
.img2{
    margin-left: 10%;
    margin-right: 10%;
    width: 80%;
}

.sp_footer p{
    color: white;
}

a{
    margin: auto;
}
@media only screen and (min-width:768px) {
    .spLogo{
        left: 114.47px;
    }
    .topBanner{
        padding-left: 20vw;
        padding-right: 20vw;
        padding-bottom: 20vh;
    }

    .topBanner > h1 {
        font-size: 52px;
    }
    
    .topBanner > h5 {
        font-size: 22px;
    }
    .text {

        font-size: 22px;
        line-height: 133.3%;
        width: 75%;
        margin: auto;
    }
    .spHeading{
        width: 75%;
        margin: auto;
        font-size: 42px;
        line-height: 56px;
        margin-top: 50px;
        margin-bottom: 80px;
    }

    .img2{
        margin-left: 10%;
        margin-right: 10%;
        width: 80%;
    }
}