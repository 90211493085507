/* @import url('https://fonts.googleapis.com/css2?family=Red+Hat+Display:wght@400;500;700&family=Red+Hat+Text&display=swap" rel="stylesheet'); */

html {font-size: 87.5%;} /*14px*/

body {
  line-height: 1.65; 
  /* font-family: 'Red Hat Display', sans-serif; */
}


::-webkit-scrollbar {
  width: 0px;  /* Remove scrollbar space */
  background: transparent;  /* Optional: just make scrollbar invisible */
}

h1, h2, h3, h4, h5, h6, nav, button, input{
    font-family: 'Red Hat Display', sans-serif;
}

h1{
  font-weight: 400;
  font-size: 1.76em;
    
}

h2 {
  font-weight: 500;
  font-size: 1.56em;
}

h3 {
    font-size: 1.14em;
    font-weight: 500;
}

h4 {
    font-size: 1.14em;
    font-weight: 500;
}

h5{
    font-size: 1.14em;
    font-weight: 400;
    /* font-family: 'Red Hat Display', sans-serif; */
}

h6 {
    font-size: 0.88em;
    font-weight: 400;
}


p {
    font-size: 1em;
    font-family: 'Red Hat Text', sans-serif;
    font-weight: 400;
}

nav{  
  font-size: 1em;  
  font-weight: 500; }

button { font-size: 1.14em;  font-weight: 500; }

input { font-size: 1.14em;  font-weight: 500; }

div#landing {
  position: relative;
  top : 146px;
  left : 0px;
  display: block;
  width: 100vw;
  min-height: 70vh;
  /* min-height: auto; */
  padding : 0px 20px;
  /* overflow : auto */
  /* box-shadow: -2px 0px 2px rgba(0, 0, 0, 0.1); */
  
}

div#side {
  position: fixed;
  /* right: 0; */
  top : 145px;
  width: 100%;
  display: none;
  align-items : center;
  text-align: center;
  padding : 12px;
  height: calc(100vh - 139px);
  box-shadow: -2px 0px 4px rgba(0, 0, 0, 0.1);
}

.footer {
  position: absolute;
  width: inherit;
  left: 0;
  bottom: -505px;
  margin-top: 200px;
}
.footerLevel2Div{
  width: 100%;
  background: #161616;

}

.addrFormControl{
  background: #F5F5F5;
  border-radius: 4px;
  margin : 20px 10px;
  width: 280px;
  border-color: transparent;
}

.quantityPicker{
  
  width:100%;
  height:31px;
}

.quantityPicker>button:focus{
  outline: none;
}

.addedBtn{
  background: #90DA7B;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 2px;
  border : transparent;
  padding : 2px 10px;
}

.addedBtn:focus{
  outline: none;
}

.loadingDiv{
  height: 610px;
}

.spinner{
  height: 60px;
  width: 60px;
  margin-top: 150px;
  margin-left: 35vw;
 }


@media only screen and (min-width:768px) {

  div#side{
    position: relative;
    width: 40%;
    margin: auto;
  }
} 

@media only screen and (min-width:1150px) {
  html {
    font-size: 100%; /*16px*/
    
  } 
  
  .main {
    width: 100%;
    display: flex;
    flex-direction: row;
  }
  
  div#landing{
    width: 74vw;
    left: 0px;
  }
  div#side{
    position: fixed;
    display: block;
    right: 0px;
    width: 26vw;
  }

  .addrFormControl{
    justify-items: center;
    margin : 20px 70px;
    width: 480px;
  }

  .loadingDiv{
    height: 390px;
  }

  .footer{
    bottom: -305px;
  }

  .spinner{
   height: 60px;
   width: 60px;
   margin: 165px 50%;
  }

}


/* @media only screen and (min-width:1441px) {
  div#root{
    width: 1440px;
  }
} */


