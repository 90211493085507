.mainImg {
    width: 100%;
    padding: 10px 10px;
    height: auto;
    margin: auto;
}

.topNav{
    /* display: grid;
    grid-template-columns: auto auto auto; */
    font-size: 12px;
    margin-top: 50px;
}
.featureContainer{
    display: grid;
    grid-template-columns: auto auto;
}

.features{
    width: 49px;
    height: 56px;
}

@media only screen and (min-width:768px) {
    .mainImg {
        width: 100%;
        height: auto;
        margin: auto;
    }
    .topNav{
        font-size: 14px;
    }
    .featureContainer{
        grid-template-columns: auto auto auto auto;
    }
}