.cart {
    height: inherit;
    position: relative;
    display: none;
}

.saved {
    position: relative;
    display: none;
    height: inherit;
    overflow-y: scroll;
    margin-bottom: 50px;
}

.profile {
    display: none;
}

.wishlistMsg{
    position: relative;
    top : 200px
}


@media only screen and (min-width:768px){
    .cart{
        display: block;
    }
}
