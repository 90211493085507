/* .cart{
    height: inherit;
    position: relative;
} */
.CardForCart {
    display: grid;
    height: 115px;
    width: inherit;
    grid-template-columns: 35% 65%;
}

.CardForCart > img{
    height: auto;
    max-width: 100%;
    margin: auto;
}


.CardForCart-body > h5 {
    max-height: 40%;
}

.quantity {
    position: absolute;
    bottom: 0%;
    width: 100%;
    margin-bottom: 5px;
}

.checkoutBtn {
    position: relative;
    margin-top: 10px;
    bottom: 4vh;
    width: 100%;
    height: 90px;
}

.checkoutBtn > p{
    margin : 10px 0px;
    height: 18px;
}

.checkoutBtn > .btn {
    background: #90DA7B;
    box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1);
    border-radius: 2px;
}
.cartItems{
    height: 42vh;
    overflow-y: scroll;
    margin-bottom: 20px;
}

@media only screen and (min-height:600px) {
    .checkoutBtn{
        margin-top: 30px;
    }
    .cartItems {
        height: 47vh
    }
} 

@media only screen and (min-height:700px) {
    .checkoutBtn{
        margin-top: 40px;
    }
    .cartItems {
        min-height: 47vh
    }
} 

@media only screen and (min-height:800px) {
    .checkoutBtn{
        margin-top: 40px;
    }
    .cartItems {
        height: 50vh
    }
}


@media only screen and (min-width:768px) {
    .CardForCart{
        height: 120px;
    }
    .checkoutBtn{
        margin-top: 60px;
        bottom: 0vh;
    }
    .cartItems {
        min-height: 50vh
    }
}