.ProductCardCheckout {
    display: grid;
    height: 105px; 
    width: inherit;
    grid-template-columns: 35% 65%;
}

.ProductCardCheckout > img{
    margin : auto;
    height: auto;
    width: 90%;
}


.ProductCardCheckout-body > h5 {
    max-height: 40%;
}

.quantity {
    position: absolute;
    bottom: 0%;
    width: 100%;
    margin-bottom: 5px;
}

.couponItem{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 15px 5px 10px 15px;
    border: 1px solid #EEEEEE;
    box-sizing: border-box;
    border-radius: 5px;
}

.appliedCoupon{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 15px 5px 10px 15px;
    box-sizing: border-box;
    border-radius: 5px;
    border: 1px solid #9BD97D;
}


@media only screen and (min-width:768px) {
    .ProductCardCheckout {
        max-height: 125px; 
        grid-template-columns: 25% 75%;
    }
}

@media only screen and (min-width:1024px) {
    .ProductCardCheckout {
        grid-template-columns: 15% 85%;
    }
}