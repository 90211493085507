.productContainer{
    display: grid;
    grid-template-columns: 50% 50%;
    min-height: 305px; /* Equal to height of the product card*/
}

/* .productContainer{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    justify-content: space-between;
    min-height: 610px;
} */

.catPageContainer{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    /* justify-content: betw; */
    /* justify-items: auto; */
    /* justify-content: left; */
}

.imgCardDiv{
    height: 137px;
    /* width: inherit; */
}
.card-img-top{
    max-height: 137px;
    margin: auto;
}

.cardBody{
    padding : 8px
}

.cardTitle{
    padding-top: 8px;
    height: 55px;
    overflow: hidden;
    width: inherit;

}
.cardProdSize{
    height: 25px;
    padding-top: 8px;
}

.cardPrice{
    height: 27px;
    padding-top: 8px;
}

.productCard {
    width: 156px;
    height: 305px;
    margin-bottom: 16px;
    /* margin-right: 5px; */
    filter: drop-shadow(0px 0px 2px rgba(0, 0, 0, 0.1));
}


.productCardBody{
    position: relative;
    margin: 8px;
    height: 129px;
}

.discount{
    padding : 3px 3px;
    position: absolute;
    top : -212px
}

.addBtn {
    /* margin-top : 8px; */
    height : 31px;
    width: 100%;
    /* border: 1px solid #000000; */
    box-sizing: border-box;
    border-radius: 3px;
    font-size: 14px;
    line-height: 21px;
    color: #373737;
    background: #FFFFFF;
    /* background: #90DA7B; */
    /* border: 1px solid #90DA7B; */
    border: 1px solid #373737;
    box-sizing: border-box;
    /* box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1); */
    border-radius: 3px;
}

.addBtn:disabled{
    background: #FFFFFF;
    border: 1px solid rgba(0, 0, 0, 0.25);
    box-sizing: border-box;
    box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1);
    border-radius: 3px;
}

.addBtn:disabled:hover{
    background: #FFFFFF;
    border: 1px solid rgba(0, 0, 0, 0.25)
}

.addBtn:hover{
    
    /* box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.4); */
    background: #90DA7B;
    border: 1px solid #90DA7B;
    box-sizing: border-box;
    box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1);
    border-radius: 3px;
}


.qtyPicker{
    margin-top : 8px;
    height : 34px;
    width: 100%;
}

@media only screen and (max-width:320px) {

    .productContainer{
        display: flex;
        flex-direction: column;
        justify-content: space-around;
    }

    .productCard {
        width: 190px;
        height: 305px;
        margin : 10px auto
    }
    .discount{
        top : -215px
    }
}

@media only screen and (min-width:370px) {
    .productCard {
        width: 162px;
        height: 305px;
    }
    .discount{
        top : -215px
    }
}

@media only screen and (min-width:410px) {
    .productCard {
        width: 184px;
        height: 305px;
    }
    .discount{
        top : -215px
    }
}

@media only screen and (min-width:768px) {

    .productContainer{
        display: grid;
        grid-template-columns: 33% 33% 33%;
        min-height: 400px;
        overflow: hidden;
    }

    .imgCardDiv{
        height: 207px;
    }
   
    .card-img-top{
        max-height: 207px;
        margin: auto;
    }

    .cardTitle{
        height: 53px;
    }

    .cardProdSize{
        height: 32px;
    }
    .cardPrice{
        height: 37px;
    }
    .addBtn {
        height: 38px;
        font-weight: 500;
        font-size: 18px;
        line-height: 26px;
        color: #373737;
    }
    .discount{
        top : -290px
    }
    .productCard {
        width: 210px;
        height: 390px;
    }
}



@media only screen and (min-width:930px) {

    .productContainer{
        grid-template-columns: 25% 25% 25% 25%;
    }
    .productCard {
        width: 200px;
    }
}

@media only screen and (min-width:1350px) {

    .productContainer{
        grid-template-columns: 25% 25% 25% 25%;
    }
    .productCard {
        width: 230px;
    }
}