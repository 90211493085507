.miniHeader {
    position: fixed;
    top : 0px;
    width: 100%;
    background-color: #318046;
    /* height: 24px; */
    height: 30px;
    padding : 0px 8px;
    z-index: 1;
}

.miniHeaderLinks {
    padding: 2px 10px;
    color : white;
    font-size: 14px;
}

.miniHeaderLinks:hover{
    color : white;
}

.header {
    position: fixed;
    top : 30px;
    width: 100%;
    /* height: 55px; */
    height: 60px;
    /* overflow: visible; */
    z-index: 2;
    background: white;
}

.header > .col-5{
    height: 100%;
}
.logo {
    position: absolute;
    left: 3.82%;
    right: 85.84%;
    top: 8.33%;
    bottom: 8.33%;
    width: 130px;
    height: 50px;
}

/* .search {
    position: absolute;
    top: 12px;
    right: 12px;
    height: 36px;
    width: 171px;
    background: #F5F5F5;
    border-radius: 5px;
    padding-left: 20px;
    border-style: none;
} */

.search {
    float: right;
    width: 171px;
    border-radius: 5px;
}


.search>input{
    width: inherit;
    height: 36px;
    background: inherit;
    background: #F5F5F5;
    border-radius: 5px;
    padding-left: 20px;
    border-style: none;
}

.search>input:focus{
    outline : none
}


/* .search>ul{
    width: inherit;
    list-style: none;
    margin-left: 0px;
} */

.searchResult {
    width: inherit;
    list-style: none;
    max-height: 300px;
    overflow-y: scroll;
    background: #FBFBFB;
    margin : 0px 0px;
    padding : 0px 10px;
}

.searchElement:hover {
    text-decoration: underline;
}

@media only screen and (min-width:768px) {
    .miniHeader {
        padding: 0px 54px;
    }

    .search {
        float: none;
        width: 650px;
    }

    .logo{
        width: 150px;
        height: 60px;
    }

}