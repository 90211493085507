.navbarCSS {
  position: fixed;
  top : 84px;
  width: 100%;
  /* height: 50px; */
  height: 55px;
  background: white;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);
  z-index: 1;
}

.dpbtn:focus{
  outline: none;
}

/* .dropbtn {
  background-color: #4CAF50;
  color: white;
  padding: 16px;
  font-size: 16px;
  border: none;
  cursor: pointer;
}
  
.dropdown {
  position: relative;
  display: inline-block;
  }
  
.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
  }
  
.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}
  
.dropdown-content a:hover {background-color: #f1f1f1}
  
.dropdown:hover .dropdown-content {
  display: block;
}
  
.dropdown:hover .dropbtn {
  background-color: #3e8e41;
}
 */
